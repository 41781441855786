import React from "react"
import { InnerTop, SiteMetadata, Restaurants, WhatsApp } from "../components"
import { Layout } from "../layouts/Layout"
import "moment/locale/es-mx"

export default () => {
  return (
    <>
      <div id="gallery-root" />
      <div id="modal-root" />
      <Layout>
        <SiteMetadata
          title="Internacional Gran Hotel"
          description="Reserva tu estadía en el hotel tradicional de El Tigre"
        />
        <WhatsApp />
        <InnerTop />
        <Restaurants />
      </Layout>
    </>
  )
}
